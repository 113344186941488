<template>
  <LayoutSuperadmin>
    <template v-slot:title>MATERIALS AND SUPPLIES</template>
    <ProductForm></ProductForm>
  </LayoutSuperadmin>
</template>

<script>
import ProductForm from '@/components/Partial/Products/ProductForm/ProductForm.vue';
import LayoutSuperadmin from '../../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
export default {
  name: 'VendorProductAdd',

  components: {
    ProductForm,
    LayoutSuperadmin,
  },
};
</script>
